import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section, Hr, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"menu"} />
		<Helmet>
			<title>
				Zirve Yasal Ortakları
			</title>
			<meta name={"description"} content={"Adalete Yolculuğunuz Burada Başlıyor"} />
			<meta property={"og:title"} content={"Zirve Yasal Ortakları"} />
			<meta property={"og:description"} content={"Adalete Yolculuğunuz Burada Başlıyor"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/668d045ee8900d0022f42770/images/1-2.jpg?v=2024-07-09T09:42:34.339Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/668d045ee8900d0022f42770/images/1-2.jpg?v=2024-07-09T09:42:34.339Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/668d045ee8900d0022f42770/images/1-2.jpg?v=2024-07-09T09:42:34.339Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/668d045ee8900d0022f42770/images/1-2.jpg?v=2024-07-09T09:42:34.339Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/668d045ee8900d0022f42770/images/1-2.jpg?v=2024-07-09T09:42:34.339Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/668d045ee8900d0022f42770/images/1-2.jpg?v=2024-07-09T09:42:34.339Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/668d045ee8900d0022f42770/images/1-2.jpg?v=2024-07-09T09:42:34.339Z"} />
			<meta name={"msapplication-TileColor"} content={"#232323"} />
		</Helmet>
		<Components.Header>
			<Override slot="link1" />
		</Components.Header>
		<Section padding="60px 0 60px 0" md-padding="30px 0 30px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-min-width="64px"
				padding="0px 90px 0px 0px"
				md-padding="0px 0px 0px 0px"
				md-width="100%"
				width="50%"
				lg-padding="0px 28px 0px 0px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				align-self="center"
			>
				<Text
					font="--headline2"
					lg-font="normal 500 32px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-font="normal 500 28px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					margin="0px 0px 32px 0px"
					color="--light"
				>
					Size Özel Kapsamlı Hukuki Çözümler
				</Text>
				<Text
					color="--light"
					font="--base"
					text-align="left"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="0px 0px 16px 0px"
					margin="0px 0px 0px 0px"
				>
					Summit Legal Partners olarak, müşterilerimizin farklı ihtiyaçlarını karşılamak için eksiksiz bir hukuki hizmet yelpazesi sunmaya kendimizi adadık. İster kişisel hukuki zorluklarla karşılaşan bir birey, ister karmaşık hukuki ortamlarda gezinen bir işletme olun, yetenekli avukatlardan oluşan ekibimiz yolun her adımında size rehberlik etmek için burada. Mükemmelliğe olan bağlılığımız, kişiselleştirilmiş yaklaşımımız ve geniş uzmanlığımız, en yüksek kalitede hukuki temsil almanızı sağlar.
				</Text>
			</Box>
			<Box
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="50%"
				md-width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
			>
				<Image
					src="https://uploads.quarkly.io/668d045ee8900d0022f42770/images/2-2.jpg?v=2024-07-09T09:42:34.333Z"
					width="100%"
					max-height="100%"
					object-fit="cover"
					lg-min-height="300px"
					md-max-height="277px"
					md-min-height="16px"
					srcSet="https://smartuploads.quarkly.io/668d045ee8900d0022f42770/images/2-2.jpg?v=2024-07-09T09%3A42%3A34.333Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/668d045ee8900d0022f42770/images/2-2.jpg?v=2024-07-09T09%3A42%3A34.333Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/668d045ee8900d0022f42770/images/2-2.jpg?v=2024-07-09T09%3A42%3A34.333Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/668d045ee8900d0022f42770/images/2-2.jpg?v=2024-07-09T09%3A42%3A34.333Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/668d045ee8900d0022f42770/images/2-2.jpg?v=2024-07-09T09%3A42%3A34.333Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/668d045ee8900d0022f42770/images/2-2.jpg?v=2024-07-09T09%3A42%3A34.333Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/668d045ee8900d0022f42770/images/2-2.jpg?v=2024-07-09T09%3A42%3A34.333Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" margin="0px 0px 0px 0px" background="--color-darkL1">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" margin="0px auto 0px auto" />
			<Box
				display="flex"
				flex-direction="column"
				margin="0px 0px 0px 0px"
				width="100%"
				sm-margin="0px 0px 30px 0px"
				padding="0px 0px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				lg-width="100%"
			>
				<Box display="flex" flex-direction="column" margin="0px 0px 48px 0px">
					<Box display="flex" align-items="center" margin="0px 0px 16px 0px">
						<Hr
							width="54px"
							margin="0px 50px 0px 0px"
							border-color="--color-lightD2"
							border-width="2px 0 0 0 "
							opacity="0.2"
							sm-margin="0px 20px 0px 0px"
							sm-width="44px"
						/>
						<Text margin="0px 0px 0px 0px" color="--green" font="--headline4" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							Hizmetlerimiz
						</Text>
					</Box>
					<Box padding="0px 0px 0px 104px" sm-padding="0px 0px 0px 64px">
						<Text margin="0px 0px 16px 0px" color="#ffffff" font="--lead" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
							- İşbirliği hukuku: Şirketler hukukunun karmaşıklıklarıyla baş etmek zor olabilir. Ekibimiz işletmelerin büyümesine yardımcı olmak için uzman hukuk danışmanlığı sağlar.
							<br />
							<br />
							- İş Kurma: Şirketler, LLC'ler ve ortaklıklar da dahil olmak üzere uygun ticari varlığın seçilmesi ve oluşturulması konusunda rehberlik.
							<br />
							<br />
							- Sözleşme Hukuku: Ticari çıkarlarınızı korumak için sözleşmelerin hazırlanması, incelenmesi ve müzakere edilmesi.
							<br />
							<br />
							- Aile Hukuku: Aile hukukuna şefkatli yaklaşımımız, zor zamanlarda ihtiyaç duyduğunuz desteği ve rehberliği almanızı sağlar.
							<br />
							<br />
							- Ceza Savunması: Haklarınızı korumak ve mümkün olan en iyi sonuçları elde etmek için güçlü savunma stratejileri sağlıyoruz.
							<br />
							<br />
							- Kişisel yaralanma: Kişisel yaralanma hizmetlerimiz, yaralanmalarınız için hak ettiğiniz tazminatı güvence altına almayı amaçlamaktadır.
							<br />
							<br />
							- Ve bu sadece başlangıç. Hukuki hizmetlerimizin tüm yelpazesini ve size nasıl yardımcı olabileceğimizi keşfetmek için sizi bizimle iletişime geçmeye davet ediyoruz. Ekibimiz özel ihtiyaçlarınızı karşılamak için ayrıntılı bilgi ve kişiselleştirilmiş danışmanlık sağlamaya hazırdır.
							<br />
							<br />
							- Bir danışma görüşmesi planlamak için bizi aramaktan veya sorularınız için bize e-posta göndermekten çekinmeyin. Size yardımcı olmak ve ihtiyacınız olan hukuki desteği sağlamak için buradayız.
						</Text>
					</Box>
				</Box>
				<Box display="flex" flex-direction="column" margin="0px 0px 0px 0px" />
			</Box>
		</Section>
		<Section
			padding="140px 0 140px 0"
			background="linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(https://uploads.quarkly.io/668d045ee8900d0022f42770/images/2-1.jpg?v=2024-07-09T09:42:34.338Z) center center/cover no-repeat"
			min-height="40vh"
			lg-padding="80px 0 80px 0"
			md-padding="96px 0 96px 0"
			sm-padding="72px 0 96px 0"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="center"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 40px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				md-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--light"
					font="--headline3"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
					width="80%"
					md-font="normal 700 42px/1.2 &quot;Source\\n\\n Sans Pro&quot;, sans-serif"
					sm-width="100%"
				>
					Sizin İçin Buradayız
				</Text>
				<Text
					margin="0px 0px 32px 0px"
					color="#FFFFFF"
					font="--lead"
					lg-text-align="center"
					text-align="center"
				>
					Summit Hukuk Ortakları olarak, size en yüksek kalitede hukuki hizmet sunmak için buradayız. Ekibimiz, ihtiyaçlarınıza özel çözümler sunmak ve sizin için en iyi sonuçları elde etmek için çalışır. Bugün bizimle iletişime geçin ve hukuki destek alın.
				</Text>
				<Link
					href="/contacts"
					padding="12px 24px 12px 24px"
					color="--light"
					background="--color-secondary"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 0px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-orange"
					sm-margin="0px 0px 0px 0px"
					sm-text-align="center"
					hover-transition="background-color 0.2s ease-in-out 0s"
				>
					İletişim
				</Link>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6166a2b829a0a1001e6ca5fb"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});